<template>
  <!-- 工资签收外部跳转 -->
  <div></div>
</template>

<script>
import { getSalarySignParam } from '@api/wx.js'
export default {
  data () {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      goto: ''
    }
  },
  created () {
    if (this.$route.query)
    {
      this.goto = this.$route.query.goto
      if (this.goto == 'salaryDetails')
      {
        sessionStorage.setItem('salaryReceiptQuery', this.$route.query ? JSON.stringify(this.$route.query) : {})
      }
    }
    this.salarySigning()
  },
  methods: {
    // 工资签收
    salarySigning () {
      getSalarySignParam({
        empcode: this.userInfo.empcode
      }).then(res => {
        let firstchangepwd = res.data[0].firstchangepwd
        let isfirstlogin = res.data[0].isfirstlogin
        let nosign = res.data[0].nosign
        if (isfirstlogin && firstchangepwd)
        {
          // 如果是首次打开并且 首次打开要强制修改密码 就去改密码界面
          this.$router.push({
            path: '/changeSalaryPsw',
            query: {
              from: 'salaryReceipt',
              firstchangepwd: firstchangepwd,
              isfirstlogin: isfirstlogin,
              nosign: nosign,
              fromH5: false,
              goto: this.goto
            }
          })
        } else
        {
          this.$router.push({ path: '/salarySiginPassword', query: { fromH5: false, goto: this.goto } })
        }
      })
    }
  }
}
</script>

<style>
</style>